@import "../../styles/customMediaQueries.css";

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 5px 0 3px 0;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.colorSwatch {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid var(--colorGrey300);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.wheel {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(red, yellow, lime, cyan, blue, magenta, red);
  animation: spin 4s linear infinite;
  display: inline-block;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  position: -webkit-sticky; /* this fixes the color wheel not appearing in web-kit

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;
}
