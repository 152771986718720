@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.numberInputWrapper {
  align-self: baseline;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 104px;
}

.numberButton {
  stroke-width: 1px;
  border-radius: 10%;
  fill: var(--colorSecondaryButton);
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: center;
  border: none;

  svg {
    pointer-events: none;
  }
}

.numberButton:hover {
  .iconContainer {
    stroke: #b8bfd1;
  }
}

.numberButton:focus {
  outline: none;

  .iconContainer {
    stroke: #b8bfd1;
  }
}
.numberButton:active {
  .iconContainer {
    fill: var(--colorGrey50);
    stroke: #b8bfd1;
  }
}
.numberButton:disabled {
  cursor: not-allowed;

  .disabled.iconContainer {
    fill: var(--colorSecondaryButton);
    stroke: #d8dce6;
  }
}

.numberInput {
  width: 60px;
  text-align: center;
  padding: 0px 10px;
  cursor: default;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
}

.iconContainer {
  fill: var(--colorWhite);
  /* stroke color set to match the color of the input 
  borders defined in marketplaceDefaults.css  */
  stroke: #d8dce6;
  stroke-width: 1px;
}

.icon {
  stroke: var(--colorGrey700);
  fill: var(--colorGrey700);
}

.disabled {
  .icon {
    stroke: var(--colorGrey200);
    fill: var(--colorGrey200);
  }
}
